// eslint-disable-next-line no-unused-vars
import SubscriptionsRepository from "@/api/SubscriptionsRepository";
import RatesRepository from "@/api/RatesRepository";
import PackagesRepository from "@/api/PackagesRepository"
import axios from 'axios'

export default {
    namespaced: true,

    state: {
        rates: [],
        ratesLoading: false,
        subscriptionsLoading: false,
        subscriptionTypes: [],
        packageTypes: [],
        packagesLoading: false,
        allProperties: [],
        propertiesLoading: false,
        invoice: {},
        invoiceLoading: false,
    },

    getters: {
        rates (state) {
            return state.rates
        },
        ratesLoading (state) {
            return state.ratesLoading
        },
        subscriptionsLoading (state) {
            return state.subscriptionsLoading
        },
        subscriptionTypes (state) {
            return state.subscriptionTypes
        },
        packageTypes (state) {
            return state.packageTypes
        },
        packagesLoading (state) {
            return state.packagesLoading
        },
        allProperties (state) {
            return state.allProperties
        },
        propertiesLoading (state) {
            return state.propertiesLoading
        },
        invoice (state) {
            return state.invoice
        },
        invoiceLoading (state) {
            return state.invoiceLoading
        },
    },

    mutations: {
        SET_RATES (state, value) {
            state.rates = value
        },
        SET_RATES_LOADING (state, value) {
            state.ratesLoading = value
        },
        SET_SUBSCRIPTIONS_LOADING (state, value) {
            state.subscriptionsLoading = value
        },
        SET_SUBSCRIPTION_TYPES (state, value) {
            state.subscriptionTypes = value
        },
        SET_PACKAGE_TYPES (state, value) {
            state.packageTypes = value
        },
        SET_PACKAGES_LOADING (state, value) {
            state.packagesLoading = value
        },
        SET_PROPERTIES (state, value) {
            state.allProperties = value
        },
        SET_LOADING_PROPERTIES (state, value) {
            state.propertiesLoading = value
        },
        SET_INVOICE (state, value) {
            state.invoice = value
        },
        SET_INVOICE_LOADING (state, value) {
            state.invoiceLoading = value
        },
    },

    actions: {
        async syncRates ({ commit }, propertyId) {
            commit('SET_RATES_LOADING', true)
            const data = await RatesRepository.getPropertyRates(propertyId)
            await commit('SET_RATES', data.data)
            commit('SET_RATES_LOADING', false)
        },
        async syncSubscriptions ({ commit }, propertyId) {
            commit('SET_SUBSCRIPTIONS_LOADING', true);
            const data = await SubscriptionsRepository.getAvailableTypes(propertyId);
            await commit('SET_SUBSCRIPTION_TYPES', data);
            commit('SET_SUBSCRIPTIONS_LOADING', false);

        },
        async syncPackages ({ commit }, propertyId) {
            commit('SET_PACKAGES_LOADING', true);
            const data = await PackagesRepository.getAvailableTypes(propertyId);
            await commit('SET_PACKAGE_TYPES', data);
            commit('SET_PACKAGES_LOADING', false);

        },
        getProperties ({ commit }) {
            commit('SET_LOADING_PROPERTIES', true)
            return axios.get('/api/user/customer/properties').then((response) => {
                commit('SET_PROPERTIES', response.data.data)
                commit('SET_LOADING_PROPERTIES', false)
            }).catch(() => {
                commit('SET_PROPERTIES', [])
                commit('SET_LOADING_PROPERTIES', false)
            })
        },
        async getInvoice ({ commit }, invoiceId) {
            commit('SET_INVOICE_LOADING', true);
            const data = await PackagesRepository.getInvoice(invoiceId);
            await commit('SET_INVOICE', data);
            commit('SET_INVOICE_LOADING', false);
        },
    }
}
